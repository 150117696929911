var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-up-main"},[_c('authHeader'),_c('div',{staticClass:"main-body signup-main"},[_c('div',{staticClass:"login-form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-6 px-0"},[_c('div',{staticClass:"signup"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 pr-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.firstName),expression:"user.firstName"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"First Name","name":"firstName"},domProps:{"value":(_vm.user.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "firstName", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6 pr-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Last Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.lastName),expression:"user.lastName"}],staticClass:"form-control",attrs:{"type":"text","name":"lastName","placeholder":"Last Name"},domProps:{"value":(_vm.user.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "lastName", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitted && _vm.$v.user.email.$error,
                      },attrs:{"type":"email","placeholder":"email@company.com","name":"email","id":"email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.user.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.email.required)?_c('span',[_vm._v("Please enter a valid corporate email")]):_vm._e(),(!_vm.$v.user.email.email)?_c('span',[_vm._v("Email is invalid")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Company Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.companyName),expression:"user.companyName"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitted && _vm.$v.user.companyName.$error,
                      },attrs:{"type":"text","placeholder":"Enter your Company Name","name":"companyName","id":"companyName"},domProps:{"value":(_vm.user.companyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "companyName", $event.target.value)}}}),(_vm.submitted && _vm.$v.user.companyName.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.companyName.required)?_c('span',[_vm._v("Please enter a Company Name")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Mobile Phone Number")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 col-md-3"},[_c('vue-country-code',{staticClass:"form-control",attrs:{"enabledFlags":false,"enabledCountryCode":true,"ignoredCountries":['VN'],"preferredCountries":['GB', 'FR', 'US']}})],1),_c('div',{staticClass:"col-9 col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.phone),expression:"user.phone"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && _vm.$v.user.phone.$error,
                          },attrs:{"type":"number","name":"phone","placeholder":"Enter your mobile Phone number","id":"phone"},domProps:{"value":(_vm.user.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "phone", $event.target.value)}}})])]),(_vm.submitted && _vm.$v.user.phone.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.phone.required)?_c('span',[_vm._v("Please enter a Phone Number")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitted && _vm.$v.user.password.$error,
                      },attrs:{"type":"password","name":"password","placeholder":"Enter your Password","id":"password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}}),(_vm.submitted && _vm.$v.user.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.password.required)?_c('span',[_vm._v("Please enter a password")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.confirmPassword),expression:"user.confirmPassword"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.user.confirmPassword.$error,
                      },attrs:{"type":"password","name":"confirmPassword","placeholder":"Enter your Confirm Password","id":"confirmPassword"},domProps:{"value":(_vm.user.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "confirmPassword", $event.target.value)}}}),(_vm.submitted && _vm.$v.user.confirmPassword.$invalid)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.confirmPassword.required)?_c('span',[_vm._v("Please enter a confirm password")]):(!_vm.$v.user.confirmPassword.sameAsPassword)?_c('span',[_vm._v("Password do not match")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"remember-me"},[_c('label',{staticClass:"custom-checkbox"},[_c('span',{staticClass:"checkbox-label"},[_vm._v("I agree with "),_c('router-link',{staticClass:"secondary-text font-lg-12 weight-600",attrs:{"to":{ name: 'term-services' }}},[_vm._v(" Terms of Service")]),_vm._v(" and "),_c('router-link',{staticClass:"secondary-text font-lg-12 weight-600",attrs:{"to":{ name: 'privacy-policy' }}},[_vm._v(" Privacy "),_c('span',{staticClass:"last-child"},[_vm._v("Statement")])])],1),_c('input',{attrs:{"type":"checkbox"}}),_c('span',{staticClass:"checkmark"})])]),_vm._m(1),_c('div',{staticClass:"text-center not-have-account mt-3"},[_c('span',{staticClass:"font-lg-12"},[_c('span',{staticClass:"light-text"},[_vm._v("Already have an account? ")]),_c('router-link',{staticClass:"secondary-text font-lg-12 weight-600",attrs:{"to":{ name: 'signin' }}},[_vm._v(" Sign in")])],1)])])])]),_vm._m(2)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heading-login"},[_c('h3',[_vm._v("Signup with Iconoclass Partnership")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center sign-btn mt-4"},[_c('button',{staticClass:"btn secondary-btn btn-block",attrs:{"type":"submit"}},[_vm._v(" Create an account ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" col-md-12 col-lg-6  px-0 desktop-screen bg-shape"},[_c('div',{staticClass:"sign-logo"},[_c('img',{attrs:{"src":require("@/assets/images/icono-logo.jpg")}})]),_c('div',{staticClass:"right-bar"},[_c('h1',[_vm._v("Sign up for free.")]),_c('ul',[_vm._v(" You are an Iconoclass fellow. Thank them,"),_c('br'),_vm._v(" thanks to them you benefit from exclusive"),_c('br'),_vm._v(" advantages ")])])])}]

export { render, staticRenderFns }