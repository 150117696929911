<template>
  <div class="sign-up-main">
    <authHeader></authHeader>
    <div class="main-body signup-main">
      <div class="login-form">
        <div class="row">
          <div class="col-md-12 col-lg-6 px-0">
            <div class="signup">
              <form @submit.prevent="onSubmit">
                <div class="heading-login">
                  <h3>Signup with Iconoclass Partnership</h3>
                </div>
                <div class="row">
                  <div class="col-md-6 pr-2">
                    <div class="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        placeholder="First Name"
                        v-model="user.firstName"
                        name="firstName"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 pr-2">
                    <div class="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        v-model="user.lastName"
                        placeholder="Last Name"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Email address</label>
                      <input
                        type="email"
                        v-model="user.email"
                        placeholder="email@company.com"
                        name="email"
                        class="form-control"
                        id="email"
                        :class="{
                          'is-invalid': submitted && $v.user.email.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.user.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.email.required"
                          >Please enter a valid corporate email</span
                        >
                        <span v-if="!$v.user.email.email"
                          >Email is invalid</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Company Name</label>
                      <input
                        type="text"
                        v-model="user.companyName"
                        placeholder="Enter your Company Name"
                        name="companyName"
                        class="form-control"
                        id="companyName"
                        :class="{
                          'is-invalid': submitted && $v.user.companyName.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.user.companyName.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.companyName.required"
                          >Please enter a Company Name</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Mobile Phone Number</label>
                      <div class="row">
                        <div class="col-3 col-md-3">
                          <vue-country-code
                            class="form-control"
                            :enabledFlags="false"
                            :enabledCountryCode="true"
                            :ignoredCountries="['VN']"
                            :preferredCountries="['GB', 'FR', 'US']"
                          >
                          </vue-country-code>
                        </div>
                        <div class="col-9 col-md-9">
                          <input
                            type="number"
                            v-model="user.phone"
                            name="phone"
                            class="form-control"
                            placeholder="Enter your mobile Phone number"
                            id="phone"
                            :class="{
                              'is-invalid': submitted && $v.user.phone.$error,
                            }"
                          />
                        </div>
                      </div>

                      <div
                        v-if="submitted && $v.user.phone.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.phone.required"
                          >Please enter a Phone Number</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        v-model="user.password"
                        name="password"
                        class="form-control"
                        placeholder="Enter your Password"
                        id="password"
                        :class="{
                          'is-invalid': submitted && $v.user.password.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.user.password.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.password.required"
                          >Please enter a password</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        v-model="user.confirmPassword"
                        placeholder="Enter your Confirm Password"
                        class="form-control"
                        id="confirmPassword"
                        :class="{
                          'is-invalid':
                            submitted && $v.user.confirmPassword.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.user.confirmPassword.$invalid"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.confirmPassword.required"
                          >Please enter a confirm password</span
                        >
                        <span
                          v-else-if="!$v.user.confirmPassword.sameAsPassword"
                          >Password do not match</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="remember-me">
                  <label class="custom-checkbox"
                    ><span class="checkbox-label"
                      >I agree with
                      <router-link
                        :to="{ name: 'term-services' }"
                        class="secondary-text font-lg-12 weight-600"
                      >
                        Terms of Service</router-link
                      >
                      and
                      <router-link
                        :to="{ name: 'privacy-policy' }"
                        class="secondary-text font-lg-12 weight-600"
                      >
                        Privacy
                        <span class="last-child">Statement</span></router-link
                      >
                    </span>
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="text-center sign-btn mt-4">
                  <button type="submit" class="btn secondary-btn btn-block">
                    Create an account
                  </button>
                </div>
                <div class="text-center not-have-account mt-3">
                  <span class="font-lg-12">
                    <span class="light-text">Already have an account? </span>
                    <router-link
                      :to="{ name: 'signin' }"
                      class="secondary-text font-lg-12 weight-600"
                    >
                      Sign in</router-link
                    >
                  </span>
                </div>
              </form>
            </div>
          </div>

          <div class=" col-md-12 col-lg-6  px-0 desktop-screen bg-shape">
            <div class="sign-logo">
              <img src="@/assets/images/icono-logo.jpg" />
            </div>

            <div class="right-bar">
              <h1>Sign up for free.</h1>
              <ul>
                You are an Iconoclass fellow. Thank them,<br />
                thanks to them you benefit from exclusive<br />
                advantages
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { SIGN_UP_ICONO, USER_EMAIL } from "../../store/constant/actions.type";
import { required, email, sameAs } from "vuelidate/lib/validators";
import UtilsService from "../../main/common/services/utils.services";
import { analyticsService } from "../../main/common/services/analytics.service";
// import { createLog } from "../../main/common/log_rocket/log-rocket-services";
import { loadingService } from "../../main/common/services/loading.service";
export default {
  components: {
    authHeader: () => import("../../main/common/auth_header/auth-header.vue"),
  },
  name: "signup",
  props: {
    enabledCountryCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: null,
      user: {
        email: null,
        firstName: null,
        phone: null,
        lastName: null,
        password: null,
        confirmPassword: null,
        referralCode: null,
        companyName: null,
      },
      fullPage: false,
      slide: 0,
      sliding: null,
      submitted: false,
    };
  },
  validations: {
    user: {
      email: { required, email },
      phone: { required },
      password: { required },
      companyName: { required },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  methods: {
    onSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        // createLog(this.user.email);
        loadingService.setloader(true);
        this.$store
          .dispatch(SIGN_UP_ICONO, this.user)
          .then((response) => {
            if (response) {
              loadingService.setloader(false);
              localStorage.setItem(USER_EMAIL, this.user.email);
              localStorage.setItem("firstTime", true);
              analyticsService.userSignup(this.user);
              this.$router.push({ name: "verification" });
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            if (
              err &&
              err.response.data &&
              err.response.data.code &&
              err.response.data.code == 2
            ) {
              this.flashMessage.error({
                title: "Error Message",
                message: "User already registered",
              });
            } else {
              this.flashMessage.error({
                title: "Error Message",
                message: err.response.data?.message,
              });
            }
          });
      }
    },
  },
  beforeMount() {
    this.code =
      localStorage.getItem("kgxdyj1Dj50aIpP4JhneyVmvQCA#referrer") || null;
    if (this.code) {
      this.code = JSON.parse(this.code);
      this.code = this.code.referralCode;
      this.user.referralCode = this.code;
    }
    const email = UtilsService.getQueryStringValue("email");
    if (email && email !== null && email !== undefined) {
      this.user.email = email;
      this.$router.push({ name: "signup" });
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
<style scoped>
.main-body {
  background: #e5e5e5;
  background-repeat: no-repeat;
  height: calc(100vh - 74.8px);
  overflow: auto;
  padding: 64px 15px 15px 15px;
}

.login-form {
  /* height: 685px; */
  color: white;
  overflow: hidden;

  max-width: 1126px;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0px 34px 80px rgb(0 0 0 / 7%);
  position: relative;
}

.signup {
  background-color: #fff;
  width: 100%;
  z-index: 2;
  /* height: 685px; */
  position: relative;
  padding: 0 15px 59px 0;
}
.signup form {
  max-width: 307px;
  margin: 0 auto;
  padding-top: 56px;
}
.right-bar {
  position: relative;
  z-index: 1;
  text-align: center;
  padding-top: 61px;
}
.right-bar h1 {
  color: #fff;
  font-size: 44px;
}
.right-bar ul {
  padding-top: 44px;
  padding-left: 0px;
  font-size: 20px;
}
.right-bar ul li {
  list-style: none;
  font-size: 16px;
  line-height: 27px;
}
.right-bar:before {
  background-color: rgb(255 255 255 / 12%);
  position: absolute;
  width: 200px;
  height: 200px;
  left: 306px;
  top: 102px;
  border-radius: 50%;
  z-index: -1;
  content: "";
}

.bg-shape {
  position: relative;
  background-color: #003172;
}
.bg-shape:before {
  content: "";
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  right: 0;
  left: -13px;
  content: "";
  position: absolute;
  bottom: 0;
  background-position: 100% 100%;
  background-size: contain;
  background-image: url("../../../assets/images/icons/login-bg.png");
}
.last-child {
  padding-left: 25px;
}
.vue-country-select.form-control {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-logo {
  background: #fff;
  border-left: 1px solid #e0e0e0;
}

.sign-logo img {
  width: 421px;
  margin: 41px 71px;
}
.heading-login h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  padding-bottom: 9px;
}

@media only screen and (max-width: 576px) {
  .main-body {
    padding: 34px 15px 15px 15px;
  }
  .login-form {
    height: 685px;
  }
}

@media only screen and (max-width: 1024px) {
  .sign-logo img {
    width: 410px;
    margin: 41px 49px;
  }
}
@media only screen and (max-width: 991px) {
  .signup form {
    max-width: 524px;
  }
  .sign-logo {
    text-align: center;
  }
  .bg-shape {
    height: 800px;
  }
  .bg-shape:before {
    content: "";
    width: 48%;
  }
}
@media only screen and (max-width: 768px) {
  .signup form {
    max-width: 524px;
  }
  .sign-logo {
    text-align: center;
  }
  .bg-shape {
    height: 751px;
  }
  .bg-shape:before {
    content: "";
    width: 63%;
  }
}
</style>
